import React from "react";

import { DefaultLayout } from "../components/layout/defaultLayout";
import { SampleWrapper } from "../components/wrapper/sampleWrapper";

const FaqPage = () => {
  return (
    <DefaultLayout pageTitle="よくある質問" headerTitle="FAQ">
      <SampleWrapper txt="よくある質問ページ" />
    </DefaultLayout>
  );
};

export default FaqPage;
